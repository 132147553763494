export function useScrolledDown() {
  const isScrolledDown = ref<boolean>(false);

  onMounted(function () {
    start();
  });

  onBeforeUnmount(function () {
    kill();
  });

  return {
    start,
    kill,
    isScrolledDown,
  };

  function start() {
    window.addEventListener('scroll', addScrollListener);
  }

  function kill() {
    window.removeEventListener('scroll', addScrollListener);
  }

  function addScrollListener() {
    if (document.body.style.position === 'fixed') {
      return;
    }

    if (window.scrollY >= 10 && !isScrolledDown.value) {
      isScrolledDown.value = true;
    } else if (window.scrollY < 10 && isScrolledDown.value) {
      isScrolledDown.value = false;
    }
  }
}
