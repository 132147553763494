import * as THREE from 'three';

export default function useBaseScene(
  canvas: HTMLCanvasElement,
  distance: number,
) {
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const time = {
    clock: new THREE.Clock(),
    previousTime: 0,
  };

  const scene = new THREE.Scene();
  scene.background = new THREE.Color();

  const renderer = new THREE.WebGLRenderer({
    antialias: false,
    alpha: true,
    canvas,
  });

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  const camera = new THREE.PerspectiveCamera(
    distance,
    sizes.width / sizes.height,
    0.1,
    500,
  );
  camera.position.set(0, 0, distance);

  const runningOnTick: ((payload: {
    deltaTime?: number;
    elapsedTime: number;
  }) => void)[] = [];

  let tickRequest: number | null;

  return {
    time,
    scene,
    sizes,
    renderer,
    setSceneBackground,
    updateRenderer,
    getWorldSizes,
    updateCamera,
    updateSizes,
    addOnTick,
    startTick,
    stopTick,
  };
  function startTick() {
    if (!tickRequest) {
      tick();
    }
  }

  function stopTick() {
    if (tickRequest) {
      window.cancelAnimationFrame(tickRequest);
      tickRequest = null;
    }
  }

  function addOnTick(
    f: (payload: { deltaTime?: number; elapsedTime: number }) => void,
  ) {
    runningOnTick.push(f);
  }

  function updateSizes() {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    updateCamera(sizes);
    updateRenderer(sizes);
  }

  function tick() {
    const elapsedTime = time.clock.getElapsedTime();
    const deltaTime = elapsedTime - time.previousTime;
    time.previousTime = elapsedTime;

    runningOnTick.forEach((f) => f({ deltaTime, elapsedTime }));

    renderer.render(scene, camera);

    tickRequest = window.requestAnimationFrame(tick);
  }

  function setSceneBackground(color: string) {
    scene.background = new THREE.Color(color);
  }

  function updateRenderer(newSizes: { width: number; height: number }) {
    renderer.setSize(newSizes.width, newSizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  function getWorldSizes() {
    const vFOV = THREE.MathUtils.degToRad(camera.fov);
    const height = 2 * Math.tan(vFOV / 2) * distance;
    const width = height * camera.aspect;

    return {
      height,
      width,
    };
  }

  function updateCamera(s: { width: number; height: number }) {
    camera.aspect = s.width / s.height;
    camera.updateProjectionMatrix();
  }
}
