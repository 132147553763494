import * as THREE from 'three';
import gsap from 'gsap';
import useRGBMLoader from '~/webgl/useRGBMLoader';
import { getLogo } from '~/webgl/getLogo';
import useBaseScene from '~/webgl/useBaseScene';

export interface Experience {
  logo: {
    container: THREE.Group;
    material: THREE.MeshStandardMaterial;
  };
  gradients: {
    top: THREE.Object3D;
    bottom: THREE.Object3D;
    hide: (options?: { immediate: boolean }) => void;
    show: (options?: { immediate: boolean }) => void;
  };
  start: () => void;
  stop: () => void;
}

export function webglExperience(
  canvas: HTMLCanvasElement,
  isReducedMotion: boolean,
): Promise<Experience | null> {
  if (isReducedMotion) {
    return animateLight();
  } else {
    return animateRegular();
  }

  async function animateLight() {
    await useWait(50);
    return null;
  }

  async function animateRegular(): Promise<Experience> {
    const textureLoader = new THREE.TextureLoader();

    const base = useBaseScene(canvas, 25);
    base.setSceneBackground('#1c1c1c');
    base.renderer.physicallyCorrectLights = true;

    /* world sizes */
    const worldSizes = base.getWorldSizes();

    /* light */
    const lightAmbient = new THREE.AmbientLight(0xffffff, 3);
    base.scene.add(lightAmbient);

    /* cubemaps */
    const { cubeRenderTarget: cubeRenderTargetCinemaBlue } =
      await useRGBMLoader(base.renderer, 'cubemap-cinema-blue-rotated');

    /* logo */
    const logoContainer = new THREE.Group();
    logoContainer.visible = false;
    base.scene.add(logoContainer);

    const { logo } = await getLogo(cubeRenderTargetCinemaBlue.texture);
    logoContainer.add(logo);

    /* background */
    const background = new THREE.Group();
    base.scene.add(background);

    const gradientTextureBlue = await textureLoader.loadAsync(
      '/images/gradient-simple-blue.webp',
    );
    const gradientTextureYellow = await textureLoader.loadAsync(
      '/images/gradient-simple-yellow.webp',
    );

    const gradientMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      map: null,
      transparent: true,
      opacity: 0,
      roughness: 0,
      metalness: 0,
    });

    const gradientMaterials = {
      blue: gradientMaterial.clone(),
      yellow: gradientMaterial.clone(),
    };
    gradientMaterials.blue.map = gradientTextureBlue;
    gradientMaterials.yellow.map = gradientTextureYellow;

    const vMax =
      worldSizes.width < worldSizes.height
        ? worldSizes.height
        : worldSizes.width;

    const gradientSizes = {
      width: vMax * 1,
      height: vMax * 1,
    };

    const gradientScaleFactor = 1.4;

    const canvasCoordinates = {
      left: 0 - worldSizes.width * 0.5,
      right: 0 + worldSizes.width * 0.5,
      top: 0 + worldSizes.height * 0.5,
      bottom: 0 - worldSizes.height * 0.5,
    };

    const gradientGeometry = new THREE.PlaneGeometry(
      gradientSizes.width,
      gradientSizes.height,
    );

    const gradientMesh1 = new THREE.Mesh(
      gradientGeometry,
      gradientMaterials.blue,
    );
    gradientMesh1.position.set(
      canvasCoordinates.left,
      canvasCoordinates.top,
      -13,
    );
    gradientMesh1.scale.set(
      gradientScaleFactor,
      gradientScaleFactor,
      gradientScaleFactor,
    );
    background.add(gradientMesh1);
    gradientMesh1.userData.initialPosition = gradientMesh1.position;

    const gradientMesh2 = new THREE.Mesh(
      gradientGeometry,
      gradientMaterials.yellow,
    );
    gradientMesh2.position.set(
      canvasCoordinates.right,
      canvasCoordinates.bottom,
      -13,
    );
    gradientMesh2.scale.set(
      gradientScaleFactor,
      gradientScaleFactor,
      gradientScaleFactor,
    );
    background.add(gradientMesh2);
    gradientMesh2.userData.initialPosition = gradientMesh2.position;

    const gradients = {
      top: gradientMesh1,
      bottom: gradientMesh2,
      hide: function (options?: { immediate: boolean }) {
        const immediate = options?.immediate === true;
        const duration = immediate ? 0 : 1.5;

        if (immediate) {
          this.bottom.material.visible = false;
          this.top.material.visible = false;
        }

        gsap.to(this.bottom.material, {
          opacity: 0,
          duration,
        });
        gsap.to(this.top.material, {
          opacity: 0,
          duration,
        });
      },
      show: function (options?: { immediate: boolean }) {
        const immediate = options?.immediate === true;
        const duration = immediate ? 0 : 4;

        this.bottom.material.visible = true;
        this.top.material.visible = true;

        gsap.to(this.bottom.material, {
          opacity: 1,
          duration,
        });
        gsap.to(this.top.material, {
          opacity: 1,
          duration,
        });
      },
    };

    /* tick */
    window.addEventListener('resize', onWindowResize);

    /* return */
    return {
      logo: {
        container: logoContainer,
        material: logo.material as THREE.MeshStandardMaterial,
      },
      gradients,
      start: base.startTick,
      stop: base.stopTick,
    };

    /* event listeners */
    function onWindowResize() {
      base.updateSizes();
    }
  }
}
