import gsap from 'gsap';
export function useScrolltriggerTimeline(
  el: HTMLElement | string,
  scrollTriggerOptions: object = {},
) {
  return gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: el,
      scrub: true,
      start: '0',
      // markers: true,
      ...scrollTriggerOptions,
    },
  });
}
