<template>
  <div ref="headerElement" class="header-default">
    <div class="content">
      <p v-if="data.eyebrow" ref="eyebrowElement" class="eyebrow text-lg">
        {{ data.eyebrow }}
      </p>
      <h1 v-if="data.title" ref="titleElement" class="title text-5xl-bold">
        {{ data.title }}
      </h1>
    </div>
    <div class="blobs">
      <img class="blob -top" src="/images/header-shape-blue-top.svg" alt="" />
      <img
        class="blob -bottom"
        src="/images/header-shape-blue-bottom.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderDefaultFragment } from '#gql';
import { useScrolltriggerTimeline } from '~/utils/useScrolltriggerTimeline';

defineProps<{
  data: HeaderDefaultFragment;
}>();

const headerElement = ref<null | HTMLElement>(null);
const eyebrowElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);

onMounted(function () {
  /* set page attributes */
  document.body.classList.add('-page-ready');
  document.body.classList.add('-ready');

  // const { $Splitting } = useNuxtApp();

  if (titleElement.value && headerElement.value && eyebrowElement.value) {
    // todo fix this
    // @ts-ignore
    // const [splitting]: Splitting.Result[] = $Splitting({
    //   target: titleElement.value,
    //   by: 'lines',
    //   key: null,
    // });
    //
    // animateLines(
    //   splitting.lines ?? [],
    //   eyebrowElement.value,
    //   headerElement.value,
    // );
  }
});

// todo: add animation
function animateLines(
  lines: HTMLElement[][],
  eyebrowElement: HTMLElement,
  trigger: HTMLElement,
) {
  const line1 = lines[0] ?? [];
  const line2 = lines[1] ?? [];

  const timelineScroll = useScrolltriggerTimeline(trigger, {
    scrub: 1,
    start: 0,
    end: '50%',
  });

  timelineScroll
    .to(line2, {
      x: -window.innerWidth * 1.5,
      ease: 'Power2.easeIn',
    })
    .to(
      line1,
      {
        x: -window.innerWidth * 1.5,
        ease: 'Power2.easeIn',
      },
      '<+=25%',
    )
    .to(
      eyebrowElement,
      {
        x: -window.innerWidth * 1.5,
        ease: 'Power2.easeIn',
      },
      '<+=25%',
    );
}
</script>

<style scoped lang="scss">
.header-default {
  padding: 0 var(--base-component-padding-inline);
  display: flex;
  align-items: center;
  position: relative;

  @media (--vs) {
    height: 80svh;
  }

  @media (--vl) {
    height: 110svh;
  }

  > .content {
    z-index: 1;
    > .eyebrow {
      padding-bottom: 1rem;
    }
  }

  > .blobs {
    //z-index: -1;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    > .blob {
      position: absolute;

      &.-top {
        inset: 0 auto auto 0;
      }

      &.-bottom {
        inset: auto 0 -60svh auto;
      }
    }
  }
}
</style>
