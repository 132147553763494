<template>
  <nav class="language-switch">
    <ul v-if="availableLocales.length > 1" class="list">
      <li class="listitem">
        <a
          :class="{ '-active': locale === 'de' }"
          :href="switchLocalePath('de')"
          class="link text-sm"
        >
          Deutsch
        </a>
      </li>
      <li class="listitem">
        <a
          :class="{ '-active': locale === 'en' }"
          :href="switchLocalePath('en')"
          class="link text-sm"
        >
          English
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const { availableLocales, locale } = useI18n();

const switchLocalePath = useSwitchLocalePath();
</script>

<style scoped lang="scss">
.language-switch {
  height: fit-content;
  position: absolute;
  inset: 2rem var(--base-component-padding-inline) 4.5rem auto;

  > .list {
    position: relative;
    display: flex;
    height: fit-content;

    > .listitem {
      position: relative;

      &:first-of-type {
        &::after {
          content: '';
          position: absolute;
          inset: 0 0 0 auto;
          height: 100%;
          background-color: #ffffff;
          opacity: 0.2;
          width: 0.0625rem;
        }
      }

      > .link {
        padding: 0 0.5rem;
        transition: opacity 200ms;
        color: var(--color-white);
        opacity: 0.7;
        text-decoration: none;

        &:hover {
          opacity: 0.9;
        }

        &.-active {
          opacity: 1;
        }
      }
    }
  }
}
</style>
