<template>
  <div
    ref="sectionIntroElement"
    class="header-3d"
    :class="{ '-scrolled': isScrolledDown, '-loading': isLoading }"
  >
    <div class="titlewrapper" role="group">
      <p class="eyebrow line text-lg">
        {{ data.eyebrow }}
      </p>
      <h1 class="title text-5xl-bold">
        <span v-if="data.titleLine1" class="span line">{{
          data.titleLine1
        }}</span
        ><br />
        <span v-if="data.titleLine2" class="span line">{{
          data.titleLine2
        }}</span>
      </h1>
    </div>
    <canvas ref="canvasEl" class="canvas" />
    <LanguageSwitch />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import type { Header3dFragment } from '#gql';
import LanguageSwitch from '~/components/unsorted/LanguageSwitch.vue';
import { type Experience, webglExperience } from '~/webgl/webglExperience';
import { useScrolledDown } from '~/utils/useScrolledDown';
import { useScrolltriggerTimeline } from '~/utils/useScrolltriggerTimeline';

defineProps<{
  data: Header3dFragment;
}>();

const nuxtApp = useNuxtApp();

const canvasEl = ref<HTMLCanvasElement | null>(null);
const canvasExperience = ref<Experience | null>(null);
const sectionIntroElement = ref<HTMLElement | null>(null);

const isLoading = ref<boolean>(true);

const { setDeepDiveLinkPath } = injectStrict(ControlPanelInjectionKey);

let tlScroll: gsap.core.Timeline;

const { isScrolledDown } = useScrolledDown();

watch(isScrolledDown, function () {
  if (!isScrolledDown.value) {
    show3dGradients({ immediate: false });
  }
});

useIntersectionObserver(sectionIntroElement, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    setDeepDiveLinkPath(null);
  }
});

onMounted(async function () {
  /* set scrolltrigger timeline */
  tlScroll = useScrolltriggerTimeline(
    sectionIntroElement.value as HTMLElement,
    {
      scrub: 1,
      start: 0,
      end: '80%',
      onEnter: function () {
        fadeCanvasLogoIn();
      },
      onLeave: function () {
        fadeCanvasLogoOut();
        hide3dGradients({ immediate: true });
      },
    },
  );

  /* get canvas */
  if (canvasEl.value) {
    canvasExperience.value = await webglExperience(
      canvasEl.value,
      nuxtApp.$isReducedMotionPreferred || nuxtApp.$isOnSlowNetwork,
    );

    if (canvasExperience.value) {
      canvasExperience.value.start();
      animate3d(canvasExperience.value);
    }
  }

  /* set page attributes */
  document.body.classList.add('-page-ready');
  document.body.classList.add('-ready');

  setTimeout(() => {
    isLoading.value = false;
  }, 50);
});

function animate3d(experience: Experience) {
  if (!tlScroll) {
    return;
  }

  if (window.scrollY <= 0) {
    show3dGradients({ immediate: false });
  }

  const lines = Array.from(
    (sectionIntroElement.value as HTMLElement).querySelectorAll('.line'),
  );

  const logoPosition = {
    min: 0,
    max: 50,
  };

  const gradientTopPosition = {
    start: {
      z: experience.gradients.top.userData.initialPosition.z,
      x: experience.gradients.top.userData.initialPosition.x,
    },
    end: {
      z: 15,
      x: 30,
    },
  };
  const gradientBottomPosition = {
    start: {
      z: experience.gradients.bottom.userData.initialPosition.z,
      x: experience.gradients.bottom.userData.initialPosition.x,
    },
    end: {
      z: 25,
      x: -30,
    },
  };

  tlScroll
    .to(lines, {
      x: -window.innerWidth * 1.5,
      ease: 'Power2.easeIn',
      stagger: -0.05,
    })
    .to(
      experience.logo.container.position,
      {
        z: logoPosition.max,
        x: 0,
        duration: 1.5,
        ease: 'SlowMo.ease.config(0.1, 0.8, false)',
      },
      '<',
    )
    .to(
      experience.logo.container.rotation,
      {
        y: Math.PI * 1.6,
        duration: 1.5,
        ease: 'SlowMo.ease.config(0.1, 0.8, false)',
      },
      '<',
    )
    .to(
      experience.gradients.top.position,
      {
        z: gradientTopPosition.end.z,
        x: gradientTopPosition.end.x,
        ease: 'linear',
      },
      '<',
    )
    .to(
      experience.gradients.bottom.position,
      {
        z: gradientBottomPosition.end.z,
        x: gradientBottomPosition.end.x,
        ease: 'linear',
      },
      '<',
    );
}

function show3dGradients(options: { immediate: boolean }) {
  if (canvasExperience.value) {
    canvasExperience.value.gradients.show(options);
  }
}

function hide3dGradients(options: { immediate: boolean }) {
  if (canvasExperience.value) {
    canvasExperience.value.gradients.hide(options);
  }
}

function fadeCanvasLogoIn() {
  if (canvasExperience.value?.logo) {
    canvasExperience.value.logo.container.visible = true;

    gsap.to(canvasExperience.value.logo.material, {
      opacity: 0.8,
      delay: 0,
      duration: 3,
    });
  }
}

function fadeCanvasLogoOut() {
  if (canvasExperience.value?.logo) {
    gsap.to(canvasExperience.value.logo.material, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        if (!isScrolledDown.value) {
          (canvasExperience.value as Experience).logo.container.visible = false;
        }
      },
    });
  }
}
</script>

<style scoped lang="scss">
.header-3d {
  position: relative;
  max-width: 100vw;
  height: 100vh;
  padding: var(--base-component-padding);
  background-color: transparent;

  @media (--vs) {
    margin-bottom: 12vh;
  }

  .blur {
    transition: filter 750ms ease-out;
  }

  &:not(.-loading) {
    .blur {
      filter: blur(0px);
    }
  }

  &.-loading {
    cursor: progress;

    > .titlewrapper {
      filter: blur(15px);
    }
  }

  > .titlewrapper {
    color: #ffffff;
    opacity: 1;
    max-width: 100vw;
    transition: opacity 2500ms;
    position: sticky;

    @media (--vs) {
      top: calc(50% - 5rem);
    }

    @media (--vl) {
      top: calc(50% - 10rem);
    }

    > .title {
      line-height: 0.95 !important;

      > .span {
        display: inline-block;
      }
    }

    > .eyebrow {
      padding-bottom: 1rem;
    }
  }

  > .canvas {
    overflow: hidden;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100svh;
    z-index: -1;
    outline: none;
    background: transparent;
    pointer-events: none;
  }
}
</style>
