import * as THREE from 'three';
import { RGBMLoader } from 'three/examples/jsm/loaders/RGBMLoader';
import { CubeTexture, WebGLRenderer } from 'three';

export default async function useRGBMLoader(
  renderer: WebGLRenderer,
  path = '',
) {
  const cubeMapImages = [
    'px.png',
    'nx.png',
    'py.png',
    'ny.png',
    'pz.png',
    'nz.png',
  ];

  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  const rgbmLoader = new RGBMLoader();

  const cubeMap: CubeTexture = await getCubeTexture(cubeMapImages);
  const cubeRenderTarget = pmremGenerator.fromCubemap(cubeMap);

  function getCubeTexture(cubeMapImages: string[]): Promise<CubeTexture> {
    // @ts-ignore
    return new Promise((resolve) => {
      rgbmLoader
        .setMaxRange(16)
        .setPath(`/cubemaps/${path}/`)
        .loadCubemap(cubeMapImages, function (cubeTexture) {
          resolve(cubeTexture);
        });
    });
  }

  return {
    cubeMap,
    cubeRenderTarget,
  };
}
