<template>
  <footer
    id="contact"
    ref="footerElement"
    class="site-footer"
    :class="{ '-visible': isVisible }"
  >
    <div class="content">
      <FooterAddress
        v-for="address in $SiteInfo.addressSet"
        :key="address.id"
        class="address"
        :data="address"
      />
    </div>
    <div class="backgrounds">
      <span class="background -red" />
      <span class="background -green" />
    </div>
  </footer>
</template>

<script setup lang="ts">
import FooterAddress from '~/components/site-footer/FooterAddress.vue';

const isVisible = ref<boolean>(false);
const footerElement = ref<HTMLElement | null>(null);

const { stop } = useIntersectionObserver(
  footerElement,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;

    if (isVisible.value) {
      stop();
    }
  },
  {
    threshold: 0.5,
  },
);
</script>

<style scoped lang="scss">
.site-footer {
  padding: var(--base-component-padding);
  overflow: hidden;
  position: relative;

  @media (orientation: portrait) {
    padding-bottom: 20lvh;
  }

  @media (orientation: landscape) {
    height: 60vh;
  }

  &.-visible {
    > .content {
      opacity: 1;
    }

    > .backgrounds {
      > .background {
        opacity: 1;
      }
    }
  }

  &:not(.-visible) {
    > .content {
      opacity: 0;
    }
  }

  > .backgrounds {
    width: 100vw;
    overflow: visible;
    pointer-events: none;
    height: 70vh;

    @media (orientation: portrait) {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;

      > .background {
        &.-red {
          transform: translate(-40%, -5%);
        }

        &.-green {
          transform: translate(40%, 65%);
        }
      }
    }

    @media (orientation: landscape) {
      position: sticky;
      bottom: 0;

      > .background {
        &.-red {
          transform: translate(-40%, -15%);
        }

        &.-green {
          transform: translate(40%, -15%);
        }
      }
    }

    > .background {
      --size: 100vw;

      display: inline-block;
      width: var(--size);
      height: var(--size);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity 1000ms;

      &.-red {
        transition-delay: 0ms;
        background-image: url('/images/gradient-simple-red.webp');
      }

      &.-green {
        transition-delay: 0ms;
        background-image: url('/images/gradient-simple-green.webp');
      }
    }
  }

  > .content {
    display: flex;
    width: 100%;
    z-index: 1;
    position: relative;

    @media (orientation: landscape) {
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      align-items: center;
    }

    @media (orientation: portrait) {
      flex-direction: column;
      gap: 30vh;

      > .address:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
}
</style>
