import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Group } from 'three';

export function useGltf(url: string): Promise<Group> {
  const gltfLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('/draco/');
  gltfLoader.setDRACOLoader(dracoLoader);

  return new Promise((resolve) => {
    gltfLoader.load(url, (gltf) => {
      resolve(gltf.scene);
    });
  });
}
